/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{ cvCardTitle }}
            </h4>
          </template>
          <template>
            <p v-if="!propOpenedInModal">
              {{ cvCardSubHeader }}
            </p>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label
                    for="validationmodule_obj_id">
                    {{cvModuleObjIdLabel}}</label>
                  <input
                    v-model="vmSubscriptionFormData.module_obj_id"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationmodule_type">
                    {{cvModuleTypeLabel}}</label>
                  <input
                    v-model="vmSubscriptionFormData.module_type"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationsubs_trial_start">
                    {{cvSubsTrialStartLabel}}</label>
                  <input
                    v-model="vmSubscriptionFormData.subs_trial_start"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationsubs_trial_end">
                    {{cvSubsTrialEndLabel}}</label>
                  <input
                    v-model="vmSubscriptionFormData.subs_trial_end"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationsubs_start_on">
                    {{cvSubsStartOnLabel}}</label>
                  <input
                    v-model="vmSubscriptionFormData.subs_start_on"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationsubs_end_on">
                    {{cvSubsEndOnLabel}}</label>
                  <input
                    v-model="vmSubscriptionFormData.subs_end_on"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationsubs_renew_date">
                    {{cvSubsRenewDateLabel}}</label>
                  <input
                    v-model="vmSubscriptionFormData.subs_renew_date"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationsubs_cancelled_on">
                    {{cvSubsCancelledOnLabel}}</label>
                  <input
                    v-model="vmSubscriptionFormData.subs_cancelled_on"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationsubs_cancel_reason">
                    {{cvSubsCancelReasonLabel}}</label>
                  <input
                    v-model="vmSubscriptionFormData.subs_cancel_reason"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationsubs_collection_method">
                    {{cvSubsCollectionMethodLabel}}</label>
                  <input
                    v-model="vmSubscriptionFormData.subs_collection_method"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationstatus">
                    {{cvStatusLabel}}</label>
                  <input
                    v-model="vmSubscriptionFormData.status"
                    type="text"
                    class="form-control"
                    required/>
                </div>
              </div>
            <div class="form-group row spinner_add">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning">
                  </b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="subscriptionAdd()">
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { Subscriptions } from "../../../FackApi/api/Subscription/subscription"
import ApiResponse from "../../../Utils/apiResponse.js"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "SubscriptionAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      apiName: "subscription_add",
      cvCardTitle: "Add subscription",
      cvCardSubHeader: "Add subscription",
      cvSubmitBtn: "Add",
      cvModuleObjIdLabel: "module obj id",
      cvModuleTypeLabel: "module type",
      cvSubsTrialStartLabel: "subs trial start",
      cvSubsTrialEndLabel: "subs trial end",
      cvSubsStartOnLabel: "subs start on",
      cvSubsEndOnLabel: "subs end on",
      cvSubsRenewDateLabel: "subs renew date",
      cvSubsCancelledOnLabel: "subs cancelled on",
      cvSubsCancelReasonLabel: "subs cancel reason",
      cvSubsCollectionMethodLabel: "subs collection method",
      cvStatusLabel: "status",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Subscription",
      vmSubscriptionFormData: Object.assign({}, this.initFormData()),
      vmSubscriptionDesc: null,
      vmSubscriptionType: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "module_obj_id": "",
        "module_type": "",
        "subs_trial_start": "",
        "subs_trial_end": "",
        "subs_start_on": "",
        "subs_end_on": "",
        "subs_renew_date": "",
        "subs_cancelled_on": "",
        "subs_cancel_reason": "",
        "subs_collection_method": "",
        "status": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmSubscriptionFormData) {
          if (!this.vmSubscriptionFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * subscriptionAdd
     */
    async subscriptionAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        let subscriptionAddResp = await Subscriptions.subscriptionAdd(this, this.vmSubscriptionFormData)
        await ApiResponse.responseMessageDisplay(this, subscriptionAddResp)
        if (subscriptionAddResp && !subscriptionAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseSubscriptionAddModal", subscriptionAddResp.resp_data)
        }
        this.vmSubscriptionFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at subscriptionAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
