<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="allCardList">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title py-3">
             <div>{{cvCardTitle}} </div>
              <span class="btn_in_header">
                <span class="" v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'">
                  <b-button variant="primary mr-2 mb-2" @click="gotoSubscriptionPlans()">Subscription Plans</b-button>
                  <b-button variant="primary mr-2 mb-2" @click="gotoPaymentTransactionList()">Transactions</b-button>
                  <b-button variant="primary mb-2" @click="gotoUserList()">Users</b-button>
                </span>
              </span>
            </h4>
          </template>
          <template>
            <div class="row mt-3 mb-3" >
              <div class="col-lg-3 col-md-4 col-sm-4 col-6" v-for="(item, index) in subPlan" :key="item.subUserPlan">
                <div class="card text-white iq-mb-2 mb-3" :style="getBgColor(index)">
                  <div class="card-body">
                    <h4 class="card-title text-white" style="text-align:center">
                      <div>{{item.subs_plan_price.toLocaleString()}}</div>
                      <h6 class="text-white">{{item.count}}</h6>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template>
            <b-row>
              <!-- Frontend Search -->
              <!-- <b-col class="col-12 col-sm-8 col-md-5 col-lg-4 mb-2">
                <b-form-input class="search_box"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input> -->
              <!-- Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-12 col-sm-8 col-md-5 col-lg-4 mb-2">
                <b-form-input class="form-control"
                  @input="searchSubsWithParams"
                  v-model="whereFilter.search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              <!-- Backend Search -->
              </b-col>
              <b-col class="col-12 col-sm-4 col-md-3 col-lg-2 mb-2">
                  <!-- Backend csv download -->
                  <b-btn variant="primary" class="Download_button" @click="backendCsvDownload">Download</b-btn><!-- Backend csv download -->
                </b-col>
              <!-- Subs plan amount filter -->
              <!-- <b-col class="mb-2 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <select v-model="whereFilter.planId" class="form-control" @change="setSubscriptionPlanAmountFilter()">
                  <option v-for="(amount, index) of payAmount" :key="(index+1)" :value="amount.subs_plan_price">
                    {{ amount.subs_plan_price}}
                  </option>
                </select>
              </b-col> -->
              <!-- Subs plan amount filter -->

               <!-- Subscription plan name filter -->
              <b-col class="col-2 col-sm-8 col-md-2 col-lg-2 mb-2">
                <select v-model="whereFilter.planId" class="form-control" @change="setSubscriptionNameFilter">
                  <option value="ALL">ALL</option>
                  <option v-for="(plan, index) of planIdList" :key="(index+1)" :value="plan.subs_plan_id" :title="plan.subs_plan_id">
                    {{ plan.subs_plan_name}}
                  </option>
                </select>
              </b-col><!-- Subscription plan name filter -->
              <b-col class="w-100 col-12 col-sm-6 col-md-12 col-lg-3 mb-2">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getSubscriptionListDateWise()" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="subscriptionObjList && subscriptionObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="subscriptionObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(user_id)="data">
                    <span class="pointer" @click="openUserProfile(data.item.user_id)">Name:&nbsp;{{ data.item.user_name }}</span><br>
                    <span><small>
                      Id:&nbsp;{{ data.item.user_id }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.user_id)"></i><br>
                      Email:&nbsp;{{ data.item.user_email }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.user_email)"></i><br>
                      Mobile:&nbsp;{{ data.item.user_mobile }}<br><br>
                      Class&nbsp;{{ data.item.class_name }}<br>
                      {{ data.item.org_name }}<br>
                      {{data.item.org_city ? data.item.org_city : '' }}
                      {{data.item.org_state ? ', ' + data.item.org_state : '' }}
                      {{data.item.org_country ? ', ' + data.item.org_country : '' }}<br>
                    </small></span>
                    <b-button variant="mr-1 mb-1" size="sm" @click="subscriptionEdit('MODAL', data.item)" v-if="!data.item.editable">
                      <i class="font-size-18 ri-ball-pen-fill m-0"></i>
                    </b-button>
                    <b-button variant="mr-1 mb-1" @click="showSubscriptionDeleteDialog(data.item)" size="sm">
                      <i class="font-size-18 ri-delete-bin-line m-0"></i>
                    </b-button>
                  </template>
                  <template v-slot:cell(status)="data">
                    {{getStatusDesc(data.item)}}
                  </template>
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelSubscriptionAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl">
      <SubscriptionAdd :propOpenedInModal="true" @emitCloseSubscriptionAddModal="closeSubscriptionAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="subscriptionAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeSubscriptionAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelSubscriptionEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl">
      <SubscriptionEdit :propOpenedInModal="true" :propSubscriptionObj="subscriptionEditObj" @emitCloseSubscriptionEditModal="closeSubscriptionEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="subscriptionEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeSubscriptionEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelSubscriptionDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelSubscriptionDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="subscriptionDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<script>
import { PayTransactionss } from "../../../FackApi/api/PayTransactions.js"
import { Subscriptions } from "../../../FackApi/api/Subscription/subscription.js"
import SubscriptionAdd from "./SubscriptionAdd.vue"
import SubscriptionEdit from "./SubscriptionEdit.vue"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import { analyticsSubscription } from "../../../FackApi/api/analytics/analytics_subscription"
import ColorCodes from "../../../Utils/colorcodes"
import { SubscriptionPlans } from "../../../FackApi/api/Subscription/subscriptionPlan"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "SubscriptionList",
  components: {
    SubscriptionAdd,
    SubscriptionEdit
  },
  data () {
    return {
      apiName: "subscription_list",
      cvCardTitle: "Subscriptions",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Subscription",
      cvAddModalHeader: "Add Subscription",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Subscription List Response",
      showModelSubscriptionAdd: false,
      showModelSubscriptionEdit: false,
      showModelSubscriptionDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "User Details", key: "user_id", class: "text-left align-text-top w-300px", sortable: true },
        { label: "Plan", key: "subs_plan_name", class: "text-left align-text-top", sortable: true },
        { label: "User Role", key: "module_type", class: "text-left align-text-top", sortable: true },
        { label: "Plan Amount", key: "subs_plan_price", class: "text-left align-text-top", sortable: true },
        { label: "Start on", key: "subs_start_on", class: "text-left align-text-top", sortable: true },
        { label: "End on", key: "subs_end_on", class: "text-left align-text-top", sortable: true },
        { label: "Renew date", key: "subs_renew_date", class: "text-left", sortable: true },
        // { label: "Trial start", key: "subs_trial_start", class: "text-left", sortable: true },
        // { label: "Trial end", key: "subs_trial_end", class: "text-left", sortable: true },
        // { label: "Cancelled on", key: "subs_cancelled_on", class: "text-left align-text-top", sortable: true },
        // { label: "Cancel reason", key: "subs_cancel_reason", class: "text-left", sortable: true },
        // { label: "Collection method", key: "subs_collection_method", class: "text-left", sortable: true },
        { label: "Status", key: "status", class: "text-left align-text-top", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true },
        { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
      ],
      subscriptionObjList: [],
      subscriptionEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null,
      subUserCount: null,
      subUserPlan: null,
      userProfileCompleted: null,
      subPlan: [],
      payAmount: [],
      planIdList: [],
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        },
        planId: "ALL",
        search_param: "",
        downloadCsv: false
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    },
    subscriptionPlanAmount () {
      return this.$store.getters["SubscriptionPlanAmount/selectedPlanAmountState"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    if (this.$route.params.student_id) {
      this.whereFilter.search_param = this.$route.params.student_id
    }

    await this.getFiltersFromCache()
    this.subscriptionList()
  },
  methods: {
    /**
     * get Status Desc
     */
    getStatusDesc (item) {
      switch (item.status) {
        case 0:
          return "Trial"
        case 1:
          return "Active"
        case 2:
          return "Cancelled"
        case 4:
          return "refunded"
        case 3:
        default:
          return "Expired"
      }
    },
    /**
     * gotoUserProfile
    */
    openUserProfile (userId) {
      window.open(`/user/${userId}`, "_blank")
    },
    /**
     * get data from store cache by this method
     */
    async getFiltersFromCache () {
      await this.getDateFilter()
      await this.getSubscriptionPlanAmountFilter()
      await this.getPlanNames()
      this.analytics_subs_planwise_usercount()
      this.payTransactionsDistinctAmountList()
    },
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
     */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter.dateRange = { ...storeDate.dateRange }
      }
    },
    /**
     * searchSubsWithParams
     */
    async searchSubsWithParams () {
      if (this.whereFilter.search_param.length <= 3) {
        return
      }
      await this.subscriptionList()
      await this.analytics_subs_planwise_usercount()
    },
    /**
   * getPlanNames
   */
    async getPlanNames () {
      try {
        const subsPlanListResp = await SubscriptionPlans.subscriptionPlanList(this)
        if (subsPlanListResp.resp_status) {
          this.planIdList = subsPlanListResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Error in getPlanNames ", err)
      }
    },
    /**
   * set filter by plan name
  */
    async setSubscriptionNameFilter () {
      await this.subscriptionList()
      await this.analytics_subs_planwise_usercount()
      await this.$store.commit("SubscriptionPlanAmount/addPlanAmount", this.whereFilter.planId)
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * set filter by planamount
    */
    async setSubscriptionPlanAmountFilter () {
      await this.subscriptionList()
      this.analytics_subs_planwise_usercount()
      await this.$store.commit("SubscriptionPlanAmount/addPlanAmount", this.whereFilter.planId)
    },
    /**
     * Get Subscription Plan Amount
     */
    async getSubscriptionPlanAmountFilter () {
      let storeData = await this.subscriptionPlanAmount
      if (storeData) {
        this.whereFilter.planId = storeData
      }
    },
    /**
     * Get SubscriptionList dateWise
     */
    async getSubscriptionListDateWise () {
      await this.subscriptionList()
      this.analytics_subs_planwise_usercount()
      this.setDateFilter()
    },
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * getBgColor
     */
    getBgColor (index) {
      return ColorCodes.getBgColor(index)
    },
    /**
     * backendCsvDownload
     */
    async backendCsvDownload () {
      this.whereFilter.downloadCsv = true
      this.subscriptionList(false, true)
    },
    /**
     * subscriptionList
     */
    async subscriptionList () {
      try {
        let subscriptionListResp = await Subscriptions.subscriptionList(this, this.whereFilter)
        if (subscriptionListResp.resp_status) {
          if (this.whereFilter.downloadCsv) {
            this.whereFilter.downloadCsv = false
            window.open(subscriptionListResp.resp_csv_file_url)
            return
          }
          this.subscriptionObjList = subscriptionListResp.resp_data.data
          this.totalRows = subscriptionListResp.resp_data.count
        }
        else {
          this.toastMsg = subscriptionListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at subscriptionList() and Exception:", err.message)
      }
    },
    /**
     * subscriptionAdd
     */
    subscriptionAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/subscription_add")
        }
        else {
          this.showModelSubscriptionAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at subscriptionAdd() and Exception:", err.message)
      }
    },
    /**
     * subscriptionEdit
     */
    subscriptionEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/subscription_edit/" + this.subscriptionEditObj.subs_id)
        }
        else {
          this.subscriptionEditObj = item
          this.showModelSubscriptionEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at subscriptionEdit() and Exception:", err.message)
      }
    },
    /**
     * showSubscriptionDeleteDialog
     */
    showSubscriptionDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelSubscriptionDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showSubscriptionDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * subscriptionDelete
     */
    async subscriptionDelete () {
      try {
        let subscriptionDelResp = await Subscriptions.subscriptionDelete(this, this.delObj.subs_id)
        await ApiResponse.responseMessageDisplay(this, subscriptionDelResp)
        if (subscriptionDelResp && !subscriptionDelResp) {
          this.showModelSubscriptionDelete = false
          return false
        }
        let index = this.subscriptionObjList.indexOf(this.delObj)
        this.subscriptionObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelSubscriptionDelete = false
      }
      catch (err) {
        console.error("Exception occurred at subscriptionDelete() and Exception:", err.message)
      }
    },
    /**
     * closeSubscriptionAddModal
     */
    closeSubscriptionAddModal (subscriptionAddData) {
      try {
        if (subscriptionAddData) {
          if (this.subscriptionObjList && this.subscriptionObjList.length >= 1) {
            let subsObjLength = this.subscriptionObjList.length
            let lastId = this.subscriptionObjList[subsObjLength - 1]["id"]
            subscriptionAddData.id = lastId + 1
          }
          else {
            this.subscriptionObjList = []
            subscriptionAddData.id = 11111
          }
          // subscriptionAddData.subs_id = `SUBS${subscriptionAddData.id}`
          subscriptionAddData.created_on = moment()
          this.subscriptionObjList.unshift(subscriptionAddData)
          this.totalRows = this.totalRows + 1
        }
        this.showModelSubscriptionAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closesubscriptionAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeSubscriptionEditModal
     */
    closeSubscriptionEditModal () {
      try {
        this.showModelSubscriptionEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeSubscriptionEditModal() and Exception:", err.message)
      }
    },
    /**
     * analytics_subs_planwise_usercount
     */
    async analytics_subs_planwise_usercount () {
      try {
        let userListResp = await analyticsSubscription.analytics_subs_planwise_usercount(this, this.whereFilter)
        if (userListResp.resp_status) {
          this.subPlan = userListResp.resp_data.data
          this.totalRows = userListResp.resp_data.count
        }
      }
      catch (err) {
        console.error("Exception occurred at analytics_subs_planwise_usercount() and Exception:", err.message)
      }
    },
    /**
     * payTransactionsDistinctAmountList
     */
    async payTransactionsDistinctAmountList () {
      try {
        let subPlanAmountListResp = await PayTransactionss.payTransactionsDistinctAmountList(this)
        if (subPlanAmountListResp.resp_status) {
          this.payAmount = subPlanAmountListResp.resp_data.data.filter(amt => {
            return amt.subs_plan_type == "Gide+"
          })
        }
      }
      catch (err) {
        console.log("Exception occurred at payTransactionsDistinctAmountList() and Exception:", err.message)
      }
    },
    /**
     * goto Subscription Plans
     */
    gotoSubscriptionPlans () {
      window.open("/subscriptionPlan_list", "_blank")
    },
    /**
     * goto PaymentTransaction List
     */
    gotoPaymentTransactionList () {
      window.open("/payTransactions_list", "_blank")
    },
    /**
     * goto User List
     */
    gotoUserList () {
      window.open("/user_list", "_blank")
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")

      document.body.appendChild(dummy)
      // Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
      console.log("Msg Copy")

      // this.snackbar = true;
      // this.err_msg = 'Copied';
    }
  }
}
</script>
<style scoped>
.dateRange {
  float: right;
}
@media screen and (max-width: 630px) {
  .btn_in_header{
    min-width: 280px;
    display: contents;
  }
}
</style>
