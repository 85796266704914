/*eslint-disable */
import request from "../../../Utils/curl"

let analyticsSubscription = {
/**
   * analytics_subs_planwise_usercount
   */
  async analytics_subs_planwise_usercount (context, whereFilter = null) {
    try {
      let postData = new FormData()
      if (whereFilter) {
        postData.append("filter", JSON.stringify(whereFilter))
      }

      return await request.curl(context, "analytics_subs_planwise_usercount", postData, process.env.VUE_APP_SUBSCRIPTION_API)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at analytics_subs_planwise_usercount() and Exception:", err.message)
    }
  }
}

export {
  analyticsSubscription
}
